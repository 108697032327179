<script lang="ts">
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
	import type { DeviceGroup, Snapshot } from "luxedo-data"
	import { openGroupEditorOverlay } from "../../../reusable/overlays/group-editor"
	import LoadingSpinner from "../../../reusable/loaders/LoadingSpinner.svelte"
	import { GroupGridManager } from "../../../../modules/device-operation-managers/GroupGridManager"
	import type { Unsubscriber } from "svelte/store"
	import DownloadIcon from "../../../reusable/icons/DownloadIcon.svelte"
	import { openSnapshotPreview } from "../../../reusable/overlays/snapshot-preview"

	export const triggerSave = undefined

	let device: DeviceGroup
	let snapshot: Snapshot

	let isShowingGrid: boolean = false
	let gridUnsubscriber: Unsubscriber

	let deviceListener

	SelectedDeviceStore.subscribe(async (dev) => {
		if (deviceListener) device.removeUpdateListener(deviceListener)

		device = dev as DeviceGroup
		updateSnapshot()

		if (gridUnsubscriber) gridUnsubscriber()
		gridUnsubscriber = GroupGridManager.subscribe((ctx) => {
			if (device.id in ctx) isShowingGrid = true
			else isShowingGrid = false
		})

		deviceListener = device.addUpdateListener(updateSnapshot)
	})

	async function updateSnapshot() {
		snapshot = await device.getSnapshot()
	}

	function downloadSnapshot() {
		snapshot.triggerDownload()
	}
</script>

<div id="projector-group-info">
	<div class="calibration-actions button-container">
		<button
			id="configure-button"
			class="outline-button"
			on:click={() => openGroupEditorOverlay(device)}>Adjust Configuration</button
		>
		<button
			id="show-grid-button"
			class="outline-button alt"
			on:click={() => {
				if (isShowingGrid) GroupGridManager.deactivateGrid(device)
				else GroupGridManager.activateGrid(device)
			}}
		>
			{#if isShowingGrid}
				Hide
			{:else}
				Show
			{/if}
			Grid
		</button>
	</div>
	<div class="group-snapshot-container">
		<div class="flex-row">
			<h3>Combined Snapshot</h3>
			<button class="icon small" id="download-snapshot-button" on:click={downloadSnapshot}>
				<DownloadIcon />
			</button>
		</div>
		<div class="snapshot-container">
			{#if snapshot}
				<img
					src={snapshot.src}
					crossorigin="anonymous"
					alt=""
					on:click={() => openSnapshotPreview(snapshot)}
				/>
			{:else}
				<LoadingSpinner color="var(--color-text)" height="8rem" />
			{/if}
		</div>
	</div>
</div>

<style>
	#projector-group-info {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	h3 {
		margin: 0 0 1rem 0;
		font-size: var(--h1);
	}

	.calibration-actions.button-container {
		margin-top: 1rem;
		align-items: flex-start;
		margin-top: 0;
	}

	#configure-button {
		flex-grow: 1;
	}

	#show-grid-button {
		flex-basis: 30%;
		margin-left: 1rem;
	}

	.group-snapshot-container {
		overflow: hidden;
		background-color: var(--color-bg-dark);
		padding: 1rem;
		border-radius: var(--br);
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	.group-snapshot-container .flex-row {
		justify-content: space-between;
	}

	#download-snapshot-button :global(.svg-fill) {
		fill: var(--color-text);
	}

	#download-snapshot-button:hover :global(.svg-fill),
	#download-snapshot-button:focus-visible :global(.svg-fill) {
		fill: var(--color-text-light);
	}

	.snapshot-container {
		flex-grow: 1;
		overflow: hidden;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.snapshot-container img {
		cursor: pointer;
	}
</style>
