import { RPCError } from "rpc-client"
import { v4 as uuid } from "uuid"
import type { GPQClient } from "../LuxedoClient"
import { CSRF_ROUTE, GPQ_ROUTE, LOG_LEVEL, POLL_INTERVAL_FAST } from "./consts"
import type {
	BackendFunction,
	BackendFunctionArgType,
	GPQCallRequest,
	GPQFunctionArgType,
	GPQFunctionsAvailableResponse,
	GPQGetFunctionsRequest,
	GPQPollRequest,
	GPQPollResponse,
	GPQRequest,
} from "./types"

/**
 * Implementation of GPQ as a backend for LuxedoRPC
 * This is middleware for the LuxedoClient implementation of RPCClient to make it easier to work with
 */
export class GPQ {
	//#region ============== SETUP & INITIALIZATION ==============

	/**
	 * @property The base API URL, such as https://api.myluxedo.com - should never include a trailing slash.
	 */
	private apiUrlBase!: string

	/**
	 * @property Session ID - used for authenticating user session with backend
	 */
	private sessionId: string
	public csrfToken!: string

	/**
	 * @property
	 * The RPC client - this will handle calling bound functions and is the interface we actually interact with
	 */
	private rpcClient: GPQClient<any>

	/**
	 * @property Optional additional base data which may be added to every outbound packet
	 */
	private customData: { [index: string]: any } = {}

	/**
	 * @property Verbosity. 0 = Error, 1 = All data transfer, 2 = Debug
	 */
	private logLevel: number = 0

	/**
	 * Initialize the GPQ backend layer
	 * @param rpcClient The active RPC client that will handle having functions called
	 */
	constructor(rpcClient: GPQClient<any>) {
		/* Create a unique session ID */
		this.sessionId = uuid()
		this.rpcClient = rpcClient
	}

	//#endregion

	//#region ============== INTERFACE ===========================

	/**
	 * Open a connection
	 */
	async openConnection(apiUrlBase?: string) {
		// Set the API URL if passed
		if (apiUrlBase) {
			this.apiUrlBase = apiUrlBase.endsWith("/") ? apiUrlBase.slice(0, -1) : apiUrlBase
		} else {
			this.apiUrlBase = ""
		}

		// First setup CSRF token
		await this.setupCSRFToken()

		// Load up our available function list
		await this.loadAvailableFunctions()

		// Begin polling
		this.startPolling(POLL_INTERVAL_FAST)
	}

	public addCustomData(key: string, value: number | string) {
		this.customData[key] = value
	}

	public removeCustomData(key: string) {
		delete this.customData[key]
	}

	/**
	 * Pause the connection
	 */
	pauseConnection() {
		this.stopPolling()
	}

	get pollInterval() {
		if (!this.pollingLoop) return 0
		return this.pollingIntervalSec
	}

	/**
	 * Change the polling rate to control bandwidth usage
	 * @param pollingIntervalSec The time between polls. 0 = off.
	 */
	set pollInterval(pollingIntervalSec: number) {
		this.startPolling(pollingIntervalSec)
	}

	/**
	 * Call a function on the backend
	 * @param functionName The name of the function to call
	 * @param args A list of args to pass
	 */
	async callRemoteFunction(functionName: string, ...args: any): Promise<any> {
		const functionData = this.backendFunctions[functionName]

		// Check the data expected from our registered server functions
		if (!functionData) throw ReferenceError(`Function ${functionName} is not registered!`)

		const allArgsData = functionData.args
		if (allArgsData.length != args.length)
			throw RangeError(`Server function ${functionName} expected ${allArgsData.length} args, got ${args.length}`)

		// Format the data appropriately
		const formattedCall: GPQCallRequest = {
			_query: "query",
			_fname: functionName,
		}

		// Iterate through passed args
		for (let argIndex = 0; argIndex < allArgsData.length; argIndex++) {
			// Parse the correct typing for this arg
			const rawArgType: BackendFunctionArgType = allArgsData[argIndex].valtype
			const argType: GPQFunctionArgType = typeof rawArgType === "string" ? rawArgType : rawArgType[0]

			const formattedArg = this.formatCalledFunctionArg(args[argIndex], argType)

			// Assign the arg to the request data
			const argName = allArgsData[argIndex].name
			formattedCall[argName] = formattedArg
		}

		// Now that the request is fully formatted, we can send it
		return await this.sendRequest<GPQCallRequest>(formattedCall)
	}

	//#region ============== AUTHENTICATION ======================

	private checkCSRFCookie() {
		// Get all cookies as a single string
		const cookies = document.cookie

		// Check if the cookie is present in the string
		const cookieExists = cookies.split(";").some((cookie) => {
			// Trim leading whitespace and check if the cookie name matches
			return cookie.trim().startsWith(`csrf_token=`)
		})

		return cookieExists
	}

	/**
	 * Begin your session by loading up your CSRF token into your headers
	 */
	private async setupCSRFToken(): Promise<void> {
		if (this.checkCSRFCookie()) {
			const cookies = document.cookie
			const cookieValue = cookies
				.split(";")
				.find((cookie) => {
					return cookie.trim().startsWith(`csrf_token=`)
				})
				?.replace("csrf_token=", "")

			this.csrfToken = cookieValue as string
			return
		}

		const request: RequestInit = {
			method: "GET",
			cache: "no-cache",
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		}

		const csrf_path =
			import.meta.env.MODE === "development" ? `${import.meta.env.VITE_API_URL}${CSRF_ROUTE}` : `${CSRF_ROUTE}`

		const response: Response = await fetch(csrf_path, request)
		/* @ts-ignore */
		const token: { csrfToken: string } = await response.json()

		this.csrfToken = token.csrfToken
	}

	//#endregion

	//#region ============== MIDDLEWARE (POLLING) ================

	private pollingLoop?: number
	private pollCount: number = 0

	/**
	 * Polling function - this handles checking for any functions from the GPQ backend we need to run
	 */
	private async poll(): Promise<void> {
		/* Format the poll request */
		const pollData: GPQPollRequest = {
			_query: "poll_standard",
			_gpqpolln: this.pollCount,
		}
		this.pollCount += 1

		/* Send the request to get the list of functions as a response */
		let response: GPQPollResponse
		try {
			response = await this.sendRequest<GPQPollRequest>(pollData)
		} catch (e) {
			throw e
		}

		/* Iterate through any functions called (if any) and call them */
		for (const funcBlock of response) {
			this.info(`Backend called function ${funcBlock.fname} with ${funcBlock.args}`)
			this.info(funcBlock)
			try {
				await this.rpcClient.onEndpointCall(funcBlock.fname, ...funcBlock.args)
			} catch (e) {
				console.warn(e)
			}
		}
	}

	private pollingIntervalSec!: number

	/**
	 * Begins polling / sets the polling rate
	 * @param pollingIntervalSec The interval (in seconds) between polls
	 */
	private startPolling(pollingIntervalSec: number): void {
		this.pollingIntervalSec = pollingIntervalSec
		this.stopPolling()
		this.debug(`Begin polling at rate ${this.pollingIntervalSec}`)
		/* @ts-ignore */
		this.pollingLoop = setInterval(this.poll.bind(this), this.pollingIntervalSec * 1000)
	}

	private stopPolling(): void {
		clearInterval(this.pollingLoop)
		this.pollingLoop = undefined
	}

	//#endregion

	//#region ============== MIDDLEWARE (FUNC SETUP) =============

	private backendFunctions: { [functionName: string]: BackendFunction } = {}

	/**
	 * Call to the server to get the available bound functions
	 */
	private async loadAvailableFunctions() {
		const request: GPQGetFunctionsRequest = {
			_query: "get_functions",
		}

		const availableFunctions: GPQFunctionsAvailableResponse = await this.sendRequest(request)

		for (const functionInfo of availableFunctions) {
			const funcName = functionInfo.fname
			const funcArgs = functionInfo.args

			this.backendFunctions[funcName] = {
				fname: funcName,
				args: funcArgs,
			}
		}
	}

	/**
	 * A necessary evil for how GPQ was implemented
	 * @param passedArg The actual value we're passing in from the frontend
	 * @param argType The type that is defined from the data we were sent from the server
	 * @returns The properly formatted arg to put in the request body
	 */
	private formatCalledFunctionArg(passedArg: any, argType: GPQFunctionArgType) {
		// if (passedArg === null) return passedArg
		// Prepare arg by type and add to request body
		switch (argType) {
			case "int":
				return parseInt(passedArg)

			case "float":
				return parseFloat(passedArg)

			case "json":
				return encodeURIComponent(JSON.stringify(passedArg))

			case "string":
			default:
				return passedArg
		}
	}

	//#endregion

	//#region ============== COMMUNICATION HANDLING ==============

	/**
	 * Prepare data for processing by the GPQ backend
	 * @param requestData A data bloc to send as a GPQ request
	 * @returns Formatted data bloc ready to be shipped
	 */
	private formatRequest<T extends GPQRequest>(requestData: T): T {
		const customData = encodeURIComponent(JSON.stringify(this.customData ?? {}))

		const preparedRequest: GPQRequest = {
			_gpqsessionid: this.sessionId,
			_gpqforeigntype: "js",
			_gpqcustom: customData,
		}

		Object.assign(preparedRequest, requestData)

		return preparedRequest as T
	}

	/**
	 * Actually send a request to the server
	 * Data will automatically be formatted according to generic
	 * @param requestData
	 */
	private async sendRequest<T extends GPQRequest>(requestData: T): Promise<any> {
		const requestBody = this.formatRequest<T>(requestData)

		const formData = new FormData()
		for (const [key, value] of Object.entries(requestBody)) {
			formData.append(key, value)
		}

		const headersInit: HeadersInit = {
			"X-CSRFToken": this.csrfToken,
		}

		const request: RequestInit = {
			method: "POST",
			mode: "cors",
			cache: "no-cache",
			headers: headersInit,
			body: formData,
			credentials: "include",
		}

		this.debug(requestBody)

		// @ts-ignore Send the response
		const response = await fetch(this.apiUrlBase + GPQ_ROUTE, request)

		// Hand it off to processing
		return await this.processResponse(response)
	}

	/**
	 * Parse the response from sendRequest and process it into a response or do errorhandling
	 * @param response
	 */
	private async processResponse(response: Response): Promise<any> {
		if (!response.ok) {
			throw new RPCError(response.statusText, response.status)
		}

		const parsedResponse = await response.json()
		this.info("Response received")
		this.debug(parsedResponse)
		return parsedResponse
	}

	//#endregion

	//#region ============== DEBUG / LOGGING =====================

	info(msg: any) {
		if (this.logLevel >= LOG_LEVEL.INFO) {
			console.group("GPQ-INFO ")
			console.warn(msg)
			console.groupEnd()
		}
	}

	debug(msg: any) {
		if (this.logLevel >= LOG_LEVEL.DEBUG) {
			console.group("GPQ-DEBUG")
			if (msg instanceof Object) {
				console.warn(JSON.stringify(msg, null, 4))
			}
			console.groupEnd()
		}
	}

	//#endregion
}

//#endregion
