<script lang="ts">
	import { Guides } from "luxedo-data"
</script>

<h1>Looking for easy content solutions?</h1>
<p>
	We are dedicated to continually expanding our library of free content for our users and are
	consistently adding new material. However, if you require content that is not yet available in our
	library, here are a few websites we recommend for quality content. Simply find the resources you
	need, download them to your desktop, and then <a
		target="_blank"
		href={Guides.default["Luxedo Portal"]["Uploading Media"]}
		>upload them to your personal Luxedo library</a
	>.
</p>
<h2>Media solutions</h2>
<p>These resources are free and can enhance your media library with a wide variety of content:</p>
<div class="link-list">
	<a target="_blank" href="https://pngimg.com/">pngimg.com</a>
	<a target="_blank" href="https://pixabay.com/">pixabay.com</a>
	<a target="_blank" href="https://favpng.com/">favpng.com</a>
</div>
<br />
<p>
	If you are looking for higher quality video content for your scenes, check out AtmosFX! Their
	content is not free but it is well worth the cost:
</p>
<div class="link-list">
	<a
		href="https://atmosfx.com/?rfsn=6031736.5d6d01&amp;utm_source=refersion&amp;utm_medium=affiliate&amp;utm_campaign=6031736.5d6d01"
		>atmosfx.com</a
	>
</div>
<h2>Show solutions</h2>
<p>
	You can also bypass the need to source media by hiring a professional service to design your
	scenes. For more information, visit our partners' pages here:
</p>
<div class="link-list">
	<a target="_blank" href="https://www.jollyholidayshows.com/">Jolly Holiday Shows</a>
	<a target="_blank" href="https://fxprojections.com/videos-1">FX Projections</a>
	<a target="_blank" href="https://www.jesterlaughs.com/">Jester Laughs</a>
	<a target="_blank" href="https://holidayprojectionmapping.com/">Holiday Projection Mapping</a>
</div>

<style>
	h1 {
		font-size: var(--text-h3);
		color: var(--color-main);
		/* margin: 0; */
	}

	h2 {
		font-size: var(--text-h4);
		color: var(--color-main);
		margin-bottom: 0.5rem;
	}

	p {
		color: var(--color-text);
		margin: 0;
	}

	.link-list a {
		margin-right: 1rem;
	}

	a {
		color: var(--color-text-light);
		border-color: var(--color-text-light);
	}

	a:hover,
	a:focus-visible {
		color: var(--color-main);
		border-color: var(--color-main);
	}
</style>
