import {
	DataHandlerDevice,
	Device,
	type BaseEidosType,
	Scene,
	Lightshow,
	DataHandlerLightshow,
	DataHandlerScene,
	TimetableEvent,
	DeviceGroup,
} from "../../index"
import { DateTime } from "luxon"
import { Controller } from "svelte-comps/stores"

type DevicePlaybackStatus_Data = {
	scene: Scene
	lightshow: Lightshow
	event: TimetableEvent
}

type DevicePlaybackStatus_Playing = {
	isPlaying: true
} & DevicePlaybackStatus_Data

type DevicePlaybackStatus_Empty = {
	isPlaying: false
} & Partial<DevicePlaybackStatus_Data>

type DevicePlaybackStatus = DevicePlaybackStatus_Playing | DevicePlaybackStatus_Empty

class DevPlaybackManager extends Controller<{ [index: number]: DevicePlaybackStatus }> {
	// store type - index: device ID, value: playback status

	constructor() {
		super({})
	}

	/**
	 * Loop through all user devices
	 * (1) create initial status blocks for each device
	 * (2) hook into eidos updates to keep status blocks up to date
	 */
	async initialize() {
		let initState: { [index: number]: DevicePlaybackStatus } = {}
		for (const device of DataHandlerDevice.getMany()) {
			initState[device.id!] = await this.getPlaybackStatus(device) // get initial playback state
			if (device instanceof DeviceGroup) {
				// if the device is a group, eidos has NO information regarding playback, so an online device is selected to be the "spokesperson" for this device group
				const child = device.getChildDevices().find((dev) => dev.isOnline)
				child.addUpdateListener(async (dev) => {
					const playbackStatus = await this.getPlaybackStatus(device)

					this.store.update((ctx) => ({
						...ctx,
						[device.id]: playbackStatus,
					}))
				})
			} else {
				device.addUpdateListener(async (dev) => {
					const playbackStatus = await this.getPlaybackStatus(dev)
					this.store.update((ctx) => ({
						...ctx,
						[dev.id]: playbackStatus,
					}))
				})
			}
		}
		this.store.set(initState)
	}

	/**
	 * Returns a boolean represenetation of if the specified device is currently playing anything
	 * @param eidos the device eidos
	 * @returns boolean representation of current playing status
	 */
	public checkIsPlaying = (device: Device) => {
		const eidos =
			device instanceof DeviceGroup ? device.getChildDevices().find((dev) => dev.isOnline)?.eidos : device.eidos
		if (!eidos) return false
		if (eidos.display_mode === "PREVIEW") return true
		if (eidos.display_mode === "TIMETABLE") {
			if (eidos.playback_type === "EMPTY") return false
			if (eidos.playback_type === "SCENE") return true
		} else return false
	}

	/**
	 * Gets the currently active event
	 * @param device
	 * @returns
	 */
	public getActiveEvent = async (device: Device) => {
		const findEvent = (showID: number, startTime: number) => {
			const start = DateTime.fromSeconds(startTime, { zone: "utc" })
			const events = device.timetableManager.getEventsInOrder(50, {
				from: start.minus({ minutes: 20 }),
				to: start.plus({ minutes: 20 }),
			})
			return events.find((e) => e.event.showId === showID)?.event
		}

		let eidos: BaseEidosType
		if (device instanceof DeviceGroup) {
			const child = device.getChildDevices().find((dev) => dev.isOnline)
			eidos = child?.eidos
		} else {
			eidos = device.eidos
		}

		if (!eidos) return undefined
		if (isNaN(eidos.proj_play_starttime) || !eidos.proj_play_starttime) return undefined

		// Use the EIDOS from the child device, but search the group's timetable for the active event - if an event is returned it is for the entire group
		return findEvent(eidos.proj_id, eidos.proj_play_starttime)
	}

	/**
	 * Creates a DevicePlaybackStatus block for the specified device, based on its current eidos status.
	 * @param device The device to create a status block for
	 * @returns the DevicePlaybackStatus block
	 */
	private getPlaybackStatus = async (device: Device) => {
		const getActiveLightshow = async (device: Device) => {
			const eidos =
				device instanceof DeviceGroup ? device.getChildDevices().find((dev) => dev.isOnline)?.eidos : device.eidos

			if (!eidos) return undefined

			const lightshow = DataHandlerLightshow.get(eidos.proj_id)
			if (lightshow && !lightshow.duration) {
				await DataHandlerLightshow.pull([eidos.proj_id])
				return await DataHandlerLightshow.get(eidos.proj_id)
			}
			return lightshow
		}

		const getActiveScene = async (device: Device, lightshow?: Lightshow) => {
			const eidos =
				device instanceof DeviceGroup ? device.getChildDevices().find((dev) => dev.isOnline)?.eidos : device.eidos

			if (!eidos) return undefined
			if (!lightshow) return DataHandlerScene.get(eidos.proj_id)

			const startTime = DateTime.fromMillis(device.eidos.proj_play_starttime * 1000, {
				zone: "utc",
			})

			const timestamp = DateTime.now().diff(startTime).as("seconds") % lightshow.duration
			const sequence = lightshow.getScenesAndTimestamps()

			let prevScene = undefined
			for (const [start, scene] of Object.entries(sequence)) {
				const startTime = Number(start)
				if (timestamp < startTime) return prevScene

				prevScene = scene
			}

			// If the loop ended, the last scene is active
			const lastScene = Object.values(sequence)[Object.values(sequence).length - 1]
			return lastScene
		}

		const isPlaying = this.checkIsPlaying(device)
		const lightshow = await getActiveLightshow(device)
		const scene = await getActiveScene(device, lightshow)
		const event = await this.getActiveEvent(device)

		return {
			isPlaying,
			lightshow,
			scene,
			event,
		}
	}
}

export const DevicePlaybackManager = new DevPlaybackManager()
