<script lang="ts">
	import Header from "./WifiHeader.svelte"
	import { Guides } from "luxedo-data"

	let time = new Date().getTime()
</script>

<main>
	<Header />
	<div class="space-between">
		<span class="heading">
			<h1>Trying to get connected to Wi-Fi?</h1>
			<span class="subtitle">Follow the instructions below for assistance</span>
		</span>

		<div class="instructions">
			<div class="connection-step">
				<span class="step_number">1</span>
				<span>
					Connect to your Luxedo device's Wi-Fi network.
					<div class="info">
						Join the Wi-Fi network named LuxedoXXXX (XXXX is your device's ID).
					</div>
					<div class="info">
						This network will not provide internet access and you may be prompted to disconnect -
						stay connected and continue.
					</div>
					<div class="info">
						Connected to your Luxedo?
						<a href="http://connect.luxedo.com?time={time}">
							<button class="small refresh-button">Refresh</button>
						</a>
					</div>
				</span>
			</div>

			<div class="connection-step">
				<span class="step_number">2</span>
				<span>
					Navigate to "connect.luxedo.com".
					<div class="info">Refresh this page or navigate to "connect.luxedo.com".</div>
					<div class="info">
						If you reach this page again, ensure you're connected to the Luxedo Wi-Fi and disable
						mobile data.
					</div>
				</span>
			</div>

			<div class="connection-step">
				<span class="step_number">3</span>
				<span>
					Click the "Connect to Wi-Fi" button and select your network.
					<div class="info">Enter the password for the network and click "Connect".</div>
					<div class="info">
						The connection step may take a minute - your Luxedo device is testing the connection.
					</div>
				</span>
			</div>
		</div>

		<p>
			For a detailed walkthrough, read <a
				target="_blank"
				href={Guides["default"]["Device"]["Connecting to Wi-Fi"]}>this article</a
			>
			or check out
			<a target="_blank" href={Guides.default["Device"]["Troubleshooting Connection To Wi-Fi"]}
				>this article</a
			>
			for help troubleshooting.<br /> For additional help, check out our
			<a target="_blank" href="https://luxedo.com/pages/help">help desk</a>.
		</p>
	</div>
</main>

<style>
	main {
		padding: 0 2rem 1rem 2rem;

		height: 100dvh;
		overflow: hidden;

		display: flex;
		flex-direction: column;
		align-items: center;
	}

	a:has(.refresh-button) {
		border: none;
		margin-left: 0.5rem;
	}

	.refresh-button {
		padding: 0 0.5rem;
	}

	.space-between {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
	}

	h1 {
		font-size: var(--h1);
		color: var(--color-main);
		margin: 1rem 0 0 0;
	}

	.heading {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.subtitle {
		font-size: var(--h3);
		color: var(--color-text);
	}

	.instructions {
		max-width: 40vw;
	}

	.step_number {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-width: 3rem;
		width: 3rem;
		height: 3rem;
		border: 1px solid var(--color-text);
		color: var(--color-text-light);

		border-radius: 99px;
		margin-right: 1rem;
		padding-bottom: 0.5rem;
		font-size: 2rem;
	}

	.connection-step {
		margin-bottom: 1rem;
		display: flex;
		flex-direction: row;
		color: var(--color-main);
		align-items: center;
	}

	.info {
		color: var(--color-text-light);
		font-size: var(--h3);
	}

	p {
		color: var(--color-text);
		margin-bottom: 0;
		text-align: center;
	}

	.underline {
		text-decoration: underline;
	}

	a {
		transition:
			border-color 250ms,
			color 250ms;
		color: var(--color-text);
		border-color: var(--color-text);
	}

	a:hover,
	a:focus-visible {
		color: var(--color-main);
		border-color: var(--color-main);
	}

	@media only screen and (max-width: 800px) {
		main {
			padding: 0.5rem 1rem;
		}

		.instructions {
			max-width: 90vw;
		}

		h1 {
			margin-top: 0.5rem;
		}

		.step_number {
			width: 2rem;
			height: 2rem;
			min-width: 2rem;
			margin-right: 0.5rem;
		}
	}
</style>
