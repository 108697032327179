type ReleaseNoteFormat = {
	[index: string]: {
		// key: version
		preview?: [string, string] // Two image links
		notes: {
			[index: string]: Array<string | { [index: string]: string }> // Key: heading, array is the content
		}
	}
}

export const LatestVersion = "5.1.1"

export const ReleaseNotes: ReleaseNoteFormat = {
	"5.1.1": {
		notes: {
			["Scene Editor"]: [
				'- The "Hide" button now deselects the hidden asset to prevent confusion',
				"- Locking an asset prevents it from being moved on the timeline",
			],
			["Bug Fixes"]: [
				'- Fixed an issue where the play now button would sometimes cause devices to go into "Recovery Mode"',
			],
		},
	},
	"5.1.0": {
		notes: {
			["Scene Editor"]: [
				"- Added a new 'Brightness Booster' feature in the scene settings of the video editor, allowing you to enhance the brightness of your videos!",
				"To apply the brightness booster, click the settings button in the video editor and adjust the 'Brightness Booster' setting to 'bright', 'brighter', or 'brightest'. Careful! The 'brightest' option may be excessively bright!",
				"- Tracks now automatically align with nearby tracks. To disable, hold down the Shift key while moving tracks.",
			],
			["Bug Fixes"]: [
				"- Fixed an issue adding an image at the end of a scene and extending the scene duration could freeze the editor",
				"- Fixed an issue where extending the duration of a scene to match the duration of an added asset would result in the scene being extended beyond the asset's duration",
			],
		},
	},
	"5.0.3": {
		notes: {
			["Bug Fixes"]: ["- Fixed an issue where the projector group grid would fail to turn off"],
		},
	},
	"5.0.2": {
		notes: {
			"": [
				"- Added 'Set as Default' option for snapshots",
				"- Added 'Audio Output' setting to relevant devices",
				"- Updated projector registration flow for projector groups",
			],
			["Bug Fixes"]: [
				"- Fixed an issue where the Scheduler would sometimes fail to close ",
				"- Fixed an issue where downloading a snapshot would sometimes fail",
			],
		},
	},
	"5.0.1": {
		notes: {
			"": ["- Updated the projector selector in the 'Projectors' tab", "- Miscellaneous quality of life changes"],
			["Bug Fixes"]: [
				"- Fixed an issue where 'Preview Last Render' would only show a portion of the rendered video",
				"- Fixed an issue where 'Preview Last Render' would occasionally show a previous version of the same scene",
			],
		},
	},
	"5.0.0": {
		preview: ["/release-content/5.0.0/preview-1.PNG", "/release-content/5.0.0/preview-2.PNG"],
		notes: {
			["Old Scenes Upgraded"]: [
				"- Scenes made in the old version of the MyLuxedo software can now be upgraded to be used in the new. Simply go to edit an old scene - a new copy will be automatically created which can be used in the new MyLuxedo scene editor. <span style='font-weight: bold;'>Due to limitations in the old video editor, some scenes may look slightly different after upgrading. Specifically, curved paths may not import exactly as before, and text formatting may change, potentially breaking lines after each word.</span>",
			],
			["Paths"]: [
				"- Path animations can now be curved.",
				"- Path animations can now be adjusted to have variable speeds.",
			],
			["Bug Fixes"]: [
				"- Fixes to directly uploading scenes.",
				"- Projector group snapshots now update more regularly.",
				"- Fixed an issue where dragging a layer into a sub-layer would remove both layers.",
				"- Fixed an issue where paths would not reset after canceling a path edit operation.",
				"- Fixed anissue where paths would sometimes render in the incorrect position after loading a saved scene.",
			],
		},
	},
	"4.11.0": {
		notes: {
			Features: [
				"- Added the 'Play Now' button allowing you to play any show on your Luxedo device at any point.",
				"- Integrated projector power controls, enabling you to power on the housed projector with a single click in the portal (this can be found in the Advanced projector settings if your device has been updated to 3.1.8).",
				"- Added timeout control allowing you to control the time it takes for the housed projector to automatically power off.",
			],
		},
	},
	"4.10.1": {
		notes: {
			Calibration: [
				"- Added minimized calibration preview.",
				"- Fixed an issue where calibration would get stuck in 'Initializing...'",
				"- Fixed an issue where calibration would falsely report a failure.",
			],
		},
	},
	"4.10.0": {
		preview: ["/release-content/4.10.0/preview-1.PNG", "/release-content/4.10.0/preview-2.PNG"],
		notes: {
			"": [
				"This update significantly improves the editor experience, providing you with much improved timeline and mask solutions.",
			],
			["Timeline"]: [
				"- Completely reworked the timeline",
				"- Rebuilt timeline zoom functionality",
				"- Rebuilt timeline drag functionality",
				"- Added loop indicators for audio and video tracks",
				"- Added audio visualization for audio and video tracks",
				"- Automatically update the length of your scene when adding a video which is longer than the scene duration",
				"- Fixed an issue where audio tracks could not be placed next to video tracks",
			],
			["Masks"]: [
				"- Added way to apply multiple masks to one track",
				"- Added way to apply masks from the edit panel",
				"- See which masks are affecting a selected object in the edit panel",
				"- Nested masks (whether visible inside or outside) apply automatically",
			],
			["Scheduler"]: [
				"- Added a time zone input, allowing you to easily manage the schedule of devices which are outside of your time zone",
			],
			" ": [
				"If you have any feedback or experience any issues, don't hesitate to let us know. Simply click the 'Help Desk' button to report an issue or provide feedback!",
			],
		},
	},
	"4.9.0": {
		preview: ["/release-content/4.9.0/blend-update.PNG", "/release-content/4.9.0/blend-update-2.PNG"],
		notes: {
			["Projector Groups"]: [
				"Creating projector groups has never been easier! This update provides a simplified way to create and manage your projector groups. Simply create a new projector group or click 'Adjust Configuration' in the 'Calibration' tab when managing an existing projector group.",
			],
		},
	},
	"4.8.0": {
		preview: ["/release-content/4.8.0/mask-editor.PNG", "/release-content/4.8.0/mask-list.PNG"],
		notes: {
			["New Mask Editor"]: [
				"To simplify scene creation, we've added a new masking tool which allows you to mask out your home before creating any scenes. After creating your masks in the new mask editor, any shows you create will automatically have those masks available!",
				" - Updated the calibration list with expandable snapshot and mask sections.",
			],
			["Scene Editor"]: [
				" - When creating new scenes, any masks created for the most recent calibration will be imported and automatically create track groups.",
				" - Media can now be sent directly to a track. If the track is masked, the media will be centered in the masked section.",
				" - Media can now be named before adding it to your scene. ",
				" - Background removal tool is now available in the scene editor.",
				" - Video trimmer tool is now available in the scene editor.",
			],
			["Miscellaneous"]: [
				" - New tutorials have been added and can be accessed through the help desk. ",
				" - Help desk can now be accessed in the scene editor using the new Help button. ",
				" - Miscellaneous style updates.",
			],
			["Bug Fixes"]: [
				" - Fixed an issue where adding a mask would sometimes not update the timeline in the scene editor.",
				" - Fixed an issue where fonts would not populate properly. ",
				" - Fixed an issue where a successful calibration would not update the calibration list.",
			],
		},
	},
	"4.7.0": {
		preview: ["/release-content/4.7.0/portal.PNG", "/release-content/4.7.0/editor.PNG"],
		notes: {
			Portal: [
				" - Restructured the entire portal to address unintuitive flows",
				" - Moved media and shows out of library and into new pages - see 'My Shows' and 'My Media'",
				" - Added a 'Schedule' page to simplify scheduling your shows",
				" - Restructured the 'Projectors' page to provide more information regarding setup and next steps",
				" - Overhauled the calibration tab in the 'Projectors' page to better show calibration data",
			],
			Editor: [
				" - Restructured the entire editor to address unintuitive flows",
				" - Added new edit panel to simplify editing, transforming and animating objects",
				" - Added new create panel to simplify adding objects to your scene",
				" - The layer panel can now be hidden",
				" - You can now schedule and play your show from the render menu",
				" - New menu to manage keyframes within the edit panel",
			],
		},
	},
	"4.6.4": {
		notes: {
			Features: [
				" - Added hide, lock and mute options to the editor",
				" - Added option to delete media source without deleting the media entirely",
			],
			"Miscellaneous Fixes": [
				" - Fixed a bug where media storage would incorrectly appear as 0gb",
				" - Fixed an issue where navigating to the previous page would redirect you to the login page",
				" - Moved projector resolution to advanced options",
			],
		},
	},
	"4.6.3": {
		notes: {
			Calibration: [
				" - Fixed a problem which would report an error, even though the calibration was still going",
				" - Snapshots can now be deleted",
			],
			Editor: [
				" - Improved performance",
				" - Resolved visual problems with the interface",
				" - Fixed an issue which caused some renders to get stuck indefinitely",
				" - Fixed an issue which caused audio media to be silent",
				" - Fixed several visual inconsistencies with rendered videos looking different from the editor",
				" - Fixed an issue which caused some renders to fail",
			],
			"Miscellaneous Fixes": [
				" - Fixed an issue where the scheduler would not load properly",
				" - Fixed an issue causing background removal to fail on certain media",
				" - Fixed an issue where the background being removed did not match the selected color",
				" - Fixed an issue which caused certain old scenes not to show up in the new portal",
			],
		},
	},
	"4.6.0": {
		preview: ["/release-content/4.6.0/4.6.0-proj-group.PNG", "/release-content/4.6.0/4.6.0-cal-supp.PNG"],
		notes: {
			"Advanced Calibration Troubleshooting": [
				" - Solutions to common v3 calibration issues added to the calibration troubleshooter.",
				" - We've brought back legacy calibration and advanced options for those projection spaces which might not work as well with the new version.",
			],
			"Projector Group Support": [
				" - You can now register a new projector group.",
				" - You can now edit projector groups under the 'Calibration' tab when a projector group is selected.",
			],
			"Miscellaneous Fixes": [
				" - Fixed an issue where editing a lightshow would not show the relevant scenes.",
				" - Fixed an issue where device passwords would not show up under the projector info tab.",
				" - Fixed an issue where the timeline scrubber would appear slightly out of position.",
				" - Miscellaneous visual adjustments.",
			],
		},
	},
	"4.5.1": {
		notes: {
			"Remove the backgrounds from your media": [
				"Have a video with a pesky green-screen background, or a black rectangle you can't get rid of? Now you can!",
				'Just right-click it in your media and choose "Edit" to get started.',
			],
			"Trim your video media down to the best parts": [
				"If your video media isn't focused enough, you can now edit it down to the highlights.",
				'Once again, right-click it in your media and choose "Edit" to get started.',
			],
		},
	},
	"4.5.0": {
		notes: {
			"Play your shows instantly": [
				'- Get more information about the shows that are playing or start a new one from the new "Now Playing" panel of the library. ',
				"- We've provided even quicker access to playing your shows directly to your projector. Just select the scene or lightshow from your library and click the play button to play it instantly on your projector.",
				"- Don't want to wait for a render or a slow download for your lightshow to play? Good news! Light shows no longer need to render to start playing.",
			],
			"Schedule your scenes": [
				"- More control over your schedule than ever before. Now when scheduling your scenes you have more control over how to show your scenes. Whether it be just a few hours or every day this month.",
				"- More ways to schedule! Shows can now be scheduled directly from the scene or lightshow details. Select a scene or lightshow and select the play button to play instantly to your projector.",
			],
		},
	},
	"4.4.1": {
		notes: {
			Scheduler: [
				"1. Repeating scenes/lightshows in the scheduler works again!",
				"2. Simplified the scheduling of multiple scenes in a row. Lightshows will still need a second to render though!",
			],
			Editor: [
				"Re-added the preview of your scene as it renders",
				"Fixed an issue causing masks not to render properly",
			],
		},
	},
	"4.3.2": {
		notes: {
			Calibration: [
				'Added the "Spotlight" to help see what your projector\'s coverage is before starting a calibration',
			],
			Misc: [
				"Continued fixes to bugs and errors, such as some scenes not being able to have their duration changed",
				"We will soon be releasing an update to overhaul the scheduler and make it much, MUCH better!",
			],
		},
	},
	"4.3.0": {
		notes: {
			"Cloning Scenes": [
				'Added in the option to duplicate your scenes - just right click your scene and click "Duplicate"!',
			],
			"Old Portal - Background Removal": ["Fixed a bug which made removing a video's background never complete"],
		},
	},
	"4.2.5": {
		notes: {
			"": [
				"We've heard your complaints, and we're working around the clock to fix the various bugs and errors you report!",
			],
			"Fixed issues causing Calibrations not to start properly": [
				"- The developer responsible for the bug has been moved outside into the cold for the rest of the season, for discipline",
				"- (for legal reasons, we would like to make clear that the above is a joke)",
			],
			Rendering: ["Fixed a number of issues causing renders to fail"],
		},
	},
	"4.2.3": {
		notes: {
			"": [
				"In this update, we've made calibration better, in particular for users experiencing heavy distortion in the sky",
			],
			"New Portal Calibration": [
				"- Heavy smudging in the sky has been significantly reduced",
				"- Grass lawns will cause less distortion at the bottom of a calibration",
				"- Trees and other foliage are now clearer and less distorted",
				"- The processing step after all the images are captured is now faster",
			],
			"Editor & Media": [
				"Fixed an issue where certain video media would not be visible in the editor",
				"- NOTE: Unfortunately, videos experiencing this issue may need to be reuploaded.",
			],
		},
	},
	"4.2.2": {
		notes: {
			"": ["This update is all about stability. "],
			"Bug Fixes": [
				"Device group snapshots will now render properly within the editor.",
				"Cancel button on the error reporting overlay is fixed.",
				"Can now open a scheduled lightshow by clicking within the calendar.",
			],
			"Quality of Life Changes": [
				"Projector status updated to be more clear.",
				"Guide list is now easier to parse.",
				"New indicator for lightshows when rendering.",
			],
		},
	},
	"4.2.0": {
		notes: {
			Calibration: [
				"Better lighting detection has been added, which should help with issues some users were having with the new Calibration!",
				"Note: Calibrations in the Legacy Website will still use the old calibration system",
			],
		},
	},
	"4.1.0": {
		notes: {
			"": [
				"In this update, we've massively improved calibration and snapshots, and released in-depth guides for the new website.",
			],
			"Calibration - Rebuilt from the ground up": [
				"Calibrating will result in much higher quality snapshots than ever before! Simply go to your projector's settings page and begin a new calibration, and we'll handle the rest.",
				"Note: Calibrations in the Legacy Website will use the old calibration system",
			],
			"Guides - Luxedo Portal Beta": [
				"Guides for our new website are now available by clicking on 'Help Desk' at the bottom left of the page",
				"Note: We are actively working to improve the site each day, so some features may not have guides yet as they are subject to change",
			],
		},
	},
	"4.0.1": {
		notes: {
			"": ["This update contains fixes to a few issues found in the Beta release."],
			"Portal Updates": [
				"Repeated events can now be scheduled and will appear correctly on the calendar.",
				"Fixed bug where an event could not be scheduled for unknown reasons.",
				"Tags have a new context menu. Right click on a tag to access it, here you can edit and delete your tags.",
			],
			"Editor Updates": ["Miscellaneous adjustments to ensure editor stability."],
			" ": [
				"We are working to streamline our development process to quickly address bugs and add legacy features. Please continue to report any issues you experience.",
			],
		},
	},
	"4.0.0": {
		preview: ["/release-content/4.0.0/Editor_promo.png", "/release-content/4.0.0/Portal_promo.png"],
		notes: {
			"": [
				"We've been working tirelessly behind the scenes to bring you a brand new and improved Luxedo Editor Portal. Today, we're thrilled to unveil the future of projection mapping creativity. Let's dive into what's new:",
			],
			"Portal Enhancements": [
				{
					"New Library Layout":
						" We've given our library a fresh look. It's now easier than ever to find and control your content.",
				},
				{
					"Enhanced Projector Control":
						" Managing your projection is a breeze with new icons, color coding, and filters. It's all about putting you in the driver's seat.",
				},
				{
					"Introducing 'Tagging'":
						" Say hello to a powerful new way to categorize and filter your content. Tag scenes with themes, ideas, or anything you like, and find them in a snap.",
				},
				{
					"Seamless Folder Integration":
						" Rest assured, your folders are still here, and we're adding backlinks for a smooth transition.",
				},
				{
					"Streamlined Scene and Lightshow Scheduling":
						" The scheduling process is now more intuitive, with lightshows fitting seamlessly into your workflow.",
				},
				{
					"Projectors View": " Get a comprehensive view of your projector and its schedule, all in one place.",
				},
			],

			"Editor Revolution": [
				{
					"Totally Revamped Editor":
						" Our editor experience has been reborn. It's faster, smoother, and virtually bug-free.",
				},
				{
					"Unleash Your Creativity":
						" Take control of your layers with newfound precision. Our powerful animation tools let your imagination run wild.",
				},
				{
					"Audio Volume Control": " Adjust audio levels for the perfect sensory experience.",
				},
				{
					Multitasking: " Work on your masterpiece while your video renders in the background.",
				},
				{
					"The Future Awaits":
						" We're committed to continuous improvement. Expect regular updates to enhance your Luxedo experience.",
				},
			],

			" ": [
				"Remember, we're dedicated to bringing all the features you loved in the old portal, improving them, and adding exciting new capabilities. Prepare to be amazed by the all-new Luxedo Editor Portal. We can't wait to see the incredible projection mapping art you'll create with these enhancements. Stay tuned for more updates. Thank you for choosing Luxedo for your projection mapping needs.",
			],
		},
	},
}
