<script lang="ts">
	import { DataHandlerDevice, Device, DeviceRPi, type ProjectorPowerState } from "luxedo-data"
	import { openConfirmOverlay } from "svelte-comps/overlay"
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
	import { Toast } from "svelte-comps/toaster"
	import { ThirdPartyProjectorManager, type Resolution } from "luxedo-data"
	import { DataSaveError } from "../../../../types/ErrorVariants"
	import { LuxedoRPC } from "luxedo-rpc"
	import { ToggleSwitch } from "svelte-comps/inputs"
	import { tooltip } from "svelte-comps/tooltip"
	import PowerButton from "../../../reusable/icons/PowerButton.svelte"
	import { ProjectorPowerManager } from "luxedo-data"

	export let triggerSave = applyChanges

	// Internal projector data
	let activeResolution
	let hasInternalProjector
	let isPowerChanging: boolean = false
	let projectorPower: ProjectorPowerState
	let newOrientation: boolean
	let cameraExposure: string

	// Timeout data
	let isTimeoutActive: boolean
	let timeoutAmount: number

	let audioDeviceValue: string

	// Component state
	let eidosUpdateListener
	let device: Device
	SelectedDeviceStore.subscribe((dev) => {
		if (eidosUpdateListener) device?.removeUpdateListener(eidosUpdateListener)
		device = dev
		eidosUpdateListener = device?.addUpdateListener(onEidosUpdate)

		const { isChanging, state } = ProjectorPowerManager.get(device?.id)
		isPowerChanging = isChanging
		projectorPower = state === "UNDEFINED" ? undefined : state

		onEidosUpdate(device)
	})

	ProjectorPowerManager.subscribe((ctx) => {
		const { isChanging, state } = ctx[device?.id] ?? { isChanging: false, state: "UNDEFINED" }
		isPowerChanging = isChanging
		projectorPower = state === "UNDEFINED" ? undefined : state
	})

	/**
	 * Called when the selected device's eidos updates or the user changes the selected device
	 * @param dev the currently selected device
	 */
	function onEidosUpdate(dev: Device) {
		console.log("eidos update!", dev)
		if (dev && dev.hasConnectedProjector) {
			if (isLuxcast(dev)) {
				const keepAliveDuration =
					dev.getEidos().config?.fw_config?.projector_keep_alive_duration ?? 0
				isTimeoutActive = keepAliveDuration > 0
				timeoutAmount = keepAliveDuration / 60
				audioDeviceValue = dev.eidos?.config?.fw_config?.audio_device
			} else {
				isTimeoutActive = false
			}

			cameraExposure = device?._rawData?.recommended_exposure
				? device?._rawData?.recommended_exposure?.toString()
				: "-1"
			hasInternalProjector = true
			activeResolution = ThirdPartyProjectorManager.resolutionManager.getByResolution(
				dev.resX,
				dev.resY
			)
			triggerSave = applyChanges
		} else {
			isTimeoutActive = false
			hasInternalProjector = false
			activeResolution = undefined
			triggerSave = undefined
		}
	}

	// #region 		=================================== 	Utilities    ===================================

	/**
	 * Ensures this device is a luxcast
	 */
	function isLuxcast(dev: Device): dev is DeviceRPi {
		return dev && dev.hasConnectedProjector
	}

	// #endregion ===================================   Utilities    ===================================
	// #region 		===================================  On User Input ===================================

	/**
	 * Applies all of changes to the selected device - resolution, projector timeout, camera exposure, ect
	 * Called when the user presses the "save" button
	 */
	async function applyChanges() {
		try {
			let hasUpdated: boolean
			const newRes = ThirdPartyProjectorManager.resolutionManager.resolutions[activeResolution]

			// Update resolution
			if (newRes && (newRes.width !== device?.resX || newRes.height !== device?.resY)) {
				await changeResolution(newRes)
				hasUpdated = true
			}

			// Update camera inversion
			if ("orientation" in device && newOrientation !== device?.orientation) {
				await LuxedoRPC.api.deviceControl.device_set_camera_flipped(
					device?.id,
					newOrientation ? 1 : 0
				)
				hasUpdated = true
			}

			// update camera exposure
			if (device?._rawData?.recommended_exposure?.toString() !== cameraExposure) {
				await LuxedoRPC.api.plato.plato_call("set_camera_exposure", [cameraExposure], device?.id!)
				hasUpdated = true
			}

			// fw_config update

			const configUpdate: typeof device.eidos.config.fw_config = {}

			if (isLuxcast(device) && device?._eidos.config.fw_config.audio_device !== audioDeviceValue) {
				configUpdate["audio_device"] = audioDeviceValue
			}

			// Update timeout
			const realTimeout = timeoutAmount * 60
			if (
				(device as DeviceRPi).getEidos()?.config?.fw_config?.projector_keep_alive_duration !==
				realTimeout
			) {
				configUpdate["projector_keep_alive_duration"] = realTimeout
			}

			if (Object.keys(configUpdate).length) {
				await LuxedoRPC.api.plato.plato_call("config_update", [configUpdate], device?.id!)
				hasUpdated = true
			}

			// await LuxedoRPC.api.plato.plato_call("set_camera_exposure", [e.currentTarget.value], device?.id!)

			if (hasUpdated) {
				await DataHandlerDevice.pull([device?.id])
				Toast.success("Device updated successfully!")
				console.error("SAVED")
			} else Toast.text("No changes detected.")
		} catch (e) {
			console.error("[ERROR] ", e)
			if (e instanceof DataSaveError) Toast.error(e.message)
			else throw e
		}
	}

	/**
	 * Verifies the user wants to remove a device from their account
	 */
	function verifyRemove() {
		openConfirmOverlay({
			prompt: [
				"Are you sure you want to remove this device from your account?",
				"This cannot be undone.",
			],
			buttons: {
				confirm: {
					text: "Yes",
					onClick: removeDevice,
				},
			},
		})
	}

	/**
	 * Called when the user changes which resolution is selected in the resolution dropdown.
	 * Does not push change to server
	 * @param e the select event
	 */
	function onResolutionInput(
		e: Event & {
			currentTarget: EventTarget & HTMLSelectElement
		}
	) {
		activeResolution = e.currentTarget.value
	}

	/**
	 * Called when the user toggles the invert camera slider
	 * @param newVal boolean - do or do not invert camera
	 */
	function onToggleInvertCamera(newVal: boolean) {
		if (!("orientation" in device)) return
		newOrientation = newVal
	}

	/**
	 * Called when the user toggles the projector timeout slider
	 * @param newVal boolean - activate or deactivate projector timeout
	 */
	async function onToggleProjectorTimeout(newVal: boolean) {
		isTimeoutActive = newVal
		if (!newVal) timeoutAmount = 0
		else
			timeoutAmount =
				(device as DeviceRPi).getEidos()?.config?.fw_config?.projector_keep_alive_duration / 60 ?? 0
	}

	/**
	 * Called when the user changes the value of the projector timeout (should only be triggered while the timeout is active)
	 * @param newTimeout the new timeout duration
	 */
	async function onProjectorTimeoutUpdate(newTimeout: number) {
		timeoutAmount = newTimeout
	}

	/**
	 * Turns the internal projector on or off (depending on current state)
	 */
	async function toggleProjectorPower() {
		let newPower: "ON" | "OFF"
		if (projectorPower === "ON") {
			newPower = "OFF"
		} else {
			newPower = "ON"
		}

		if (isLuxcast(device)) {
			try {
				Toast.text(`Powering projector ${newPower.toLowerCase()}`)
				await ProjectorPowerManager.setPower(device, newPower)
				Toast.success(`The projector has powered ${newPower.toLowerCase()}`)
			} catch (e) {
				console.error("Unable to modify projector power state.")
				Toast.error(`Unable to power ${newPower.toLowerCase()} the projector`)
			}
		}
	}

	// #endregion ===================================  On User Input ===================================
	// #region		===================================  Modify Device ===================================

	/**
	 * Changes the resolution of the selected device to the new provided resolution
	 * @param newResolution the new resolution
	 */
	async function changeResolution(newResolution: Resolution): Promise<boolean> {
		if (!device?.isReady)
			throw new DataSaveError("Device is offline or busy - please wait and try again")
		if (!(device instanceof DeviceRPi) || !device?.hasConnectedProjector)
			throw new DataSaveError("Cannot change resolution of this device?.")
		if (device?.isResolutionChanging)
			throw new DataSaveError("Cannot change resolution while awaiting response.")

		if (device?.resX === newResolution.width && device?.resY === newResolution.height) return true

		device.isResolutionChanging = true

		try {
			device.resX = newResolution.width
			device.resY = newResolution.height

			await LuxedoRPC.api.plato.plato_call(
				"display_set_resolution",
				[newResolution.width, newResolution.height, 60, "_all_other_projectors"],
				device?.id!
			)

			device.isResolutionChanging = false
			return true
		} catch (e) {
			device.isResolutionChanging = false
			throw e
		}
	}

	/**
	 * Removes the selected device from the logged in user's account
	 */
	async function removeDevice() {
		try {
			await DataHandlerDevice.deleteEntry(device)
			const devices = DataHandlerDevice.getMany()
			if (devices.length) SelectedDeviceStore.set(devices[0])
			else SelectedDeviceStore.set(undefined)
			Toast.success("Device successfully removed from your account.")
		} catch (e) {
			console.error("Error removing device from user account: ", e)
			Toast.error(
				"An error occurred while trying to remove this device from your account. Please try again..."
			)
		}
	}

	// #endregion ===================================  Modify Device ===================================
</script>

<div class="advanced-options">
	{#if hasInternalProjector && isLuxcast(device)}
		<div class="flex-row projector-heading">
			<h2 class="setting-heading">Projector</h2>
			<!-- {#if projectorPower || isPowerChanging} -->
			<PowerButton
				isOn={projectorPower === "ON"}
				togglePower={toggleProjectorPower}
				isChanging={isPowerChanging}
			/>
			<span class="power-control-info"
				>{ProjectorPowerManager.convertToReadableState(projectorPower)}</span
			>

			<!-- {/if} -->
		</div>

		<!-- <div> -->
		<div class="select-container">
			<label id="projector-resolution-label" for="resolution-input">Projector Resolution: </label>
			<select id="resolution-input" on:change={onResolutionInput} value={activeResolution}>
				{#each Object.entries(ThirdPartyProjectorManager.getSupportedResolutions()) as [name, res], i}
					<option value={name}>{res.width} x {res.height}</option>
				{/each}
			</select>
		</div>

		<div class="flex-row projector-timeout-container">
			<div class="switch-container">
				<span
					class="label projector-timeout"
					use:tooltip={{
						content:
							"The duration between the last active projection and the projector's automatic shutdown to conserve energy.",
						pointing: "top",
					}}
					>Projector Timeout:
				</span>
				<ToggleSwitch
					isActive={isTimeoutActive}
					onUpdate={onToggleProjectorTimeout}
					info={"If your calibration images appear upside down, toggle this to flip the camera orientation."}
				/>
			</div>
			{#if isTimeoutActive}
				<input
					type="number"
					name=""
					id=""
					bind:value={timeoutAmount}
					on:change={(e) => onProjectorTimeoutUpdate(Number(e.currentTarget.value))}
				/>
				min
			{/if}
		</div>

		<!-- <span class="resolution-warning"> -->
		<!-- Most  -->
		<h2 class="setting-heading camera">Camera Settings</h2>
		<div class="select-container">
			<label id="custom-exposure-label" for="camera-exposure">Camera Exposure: </label>
			<select id="camera-exposure" bind:value={cameraExposure}>
				<option value="-1">Automatic</option>
				<option value="32">Very Low</option>
				<option value="64">Low</option>
				<option value="128">Mid-Low</option>
				<option value="256">Standard</option>
				<option value="512">Mid-High</option>
				<option value="1024">High</option>
				<option value="2048">Very High</option>
			</select>
		</div>
		<!-- </span> -->
		<!-- </div> -->
	{:else}
		<div />
	{/if}

	{#if "orientation" in device}
		<div class="flex-row invert-camera-container">
			<div class="switch-container">
				<span class="label">Invert Camera: </span>
				<ToggleSwitch
					isActive={!!device?.orientation}
					onUpdate={onToggleInvertCamera}
					info={"If your calibration images appear upside down, toggle this to flip the camera orientation."}
				/>
			</div>
		</div>
	{/if}

	{#if isLuxcast(device)}
		<h2 class="setting-heading camera">Audio Settings</h2>
		<div class="select-container">
			<label for="audio-config-input">Audio Output: </label>
			<select name="audio-config" id="audio-config-input" bind:value={audioDeviceValue}>
				<option value="HDMI">HDMI</option>
				<option value="HEADPHONES">3.5mm Jack</option>
			</select>
		</div>
	{/if}

	<div class="button-container">
		<button
			id="remove-device-button"
			class="outline-button"
			title="Remove Device from Account"
			on:click={verifyRemove}
		>
			Remove from Account
		</button>
	</div>
</div>

<style>
	.advanced-options {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	.projector-timeout-container .switch-container {
		width: 12.25rem;
	}

	#projector-resolution-label {
		display: flex;
		flex-direction: row;
	}

	.switch-container {
		width: 11rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.button-container {
		flex-direction: column;
		align-items: flex-start;
		margin-top: 1rem;
	}

	.flex-row {
		align-items: center;
	}

	.invert-camera-container :global(.info-dialogue-button) {
		margin-left: 0;
		margin-bottom: 0.5rem;
	}
	.label,
	label {
		color: var(--color-text);
		white-space: nowrap;
		width: fit-content;
	}

	select {
		color: var(--color-text-light);
		width: 100%;
		margin-left: 1rem;
		transition: background-color 250ms;
	}

	input[type="number"] {
		background-color: unset;
		color: var(--color-text-light);
		margin: 0 0.5rem;
		border: none;
		padding: 0;
		box-shadow: none;
		width: 2rem;
		border-radius: 0;
		text-align: end;
	}

	select:focus-visible,
	select:hover {
		background-color: var(--color-main-transparent);
	}

	.power-control-info {
		margin-left: 0.5rem;
		color: var(--color-text);
		font-size: var(--h3);
	}

	#remove-device-button {
		border-color: var(--color-error);
	}

	#remove-device-button:hover,
	#remove-device-button:focus-visible {
		background-color: var(--color-error);
	}

	.setting-heading {
		margin: 0;
		color: var(--color-text-light);
		font-size: var(--h1);
	}

	.setting-heading.camera {
		margin-top: 1rem;
	}

	.projector-heading h2 {
		margin-right: 0.5rem;
	}
</style>
