import { DateTime } from "luxon"
import { Device, TEMPORARY_PLAY_NOW_EVENT_NAME } from "../.."
import {
	TimetableEvent,
	TimetableEventRepeat,
	TimetableEventSingle,
	type FullCalendarEvent,
	type TimetableRawData,
	type EventOccurrence,
} from "./TimetableEvent"
import { EventImpl } from "@fullcalendar/core/internal"
import { v4 as uuid } from "uuid"

/** Handles pulling data  */

type Updater = (events: Array<TimetableEvent>) => void

export class Timetable {
	declare device: Device
	declare events: Array<TimetableEvent>

	declare lastUpdated: Date
	declare version: string

	declare listeners: { [index: string]: Updater }

	constructor(device: Device) {
		this.device = device
		this.events = []
		this.listeners = {}
	}

	// #region Listeners

	public addListener(updater: Updater): string {
		const id = uuid()
		this.listeners[id] = updater
		return id
	}

	public removeListener(id: string) {
		if (!this.listeners[id]) return
		delete this.listeners[id]
	}

	private triggerListeners() {
		for (const updater of Object.values(this.listeners)) {
			updater(this.events)
		}
	}

	// #endregion Listeners
	// #region Interface

	/**
	 * Finds the event which uses the specified project and start time
	 * @param projectId number - the project ID
	 * @param startTime DateTime - the start time of the event
	 * @param exact boolean - if true, returns the event of that project ID with the closest start date (for eidos playtime interpolation)
	 * @returns
	 */
	findEvent(projectId: number, startTime: DateTime): TimetableEvent | undefined {
		return this.events.find((event) => {
			const eventTiming = event.getTimingValues()
			const start = DateTime.fromISO(eventTiming.start)
			const end = DateTime.fromISO(eventTiming.end)
			return startTime >= start && startTime < end && event.show.id === projectId
		})
	}

	/**
	 * Finds a timetable event with the provided id
	 */
	getEvent(id: string): TimetableEvent
	getEvent(fcEvent: FullCalendarEvent): TimetableEvent
	getEvent(eventData: string | FullCalendarEvent): TimetableEvent {
		let id
		if (eventData instanceof EventImpl) id = eventData.extendedProps.eventId
		else id = eventData

		return this.events.find((event) => event.id === id)!
	}

	/**
	 * Gets a list of events according to the speicified from, to times
	 * @param limit The amount of events to receive
	 * @param options The from / to time
	 * @returns
	 */
	getEventsInOrder(limit: number = 10, options?: { from?: DateTime; to?: DateTime }): Array<EventOccurrence> {
		const allEvents = this.events
		const { from, to } = options ?? {}

		const eventOccurrences: { [index: string]: EventOccurrence[] } = {}

		for (const event of allEvents) {
			eventOccurrences[event.id] = event.getOccurrences()
		}

		const allOccurrences: Array<EventOccurrence> = []

		let i = 0
		for (const occurrences of Object.values(eventOccurrences)) {
			if (i >= limit) break
			for (const occurrence of occurrences) {
				if (i >= limit) break
				if (from && occurrence.start < from) continue
				if (to && occurrence.start > to) continue

				allOccurrences.push(occurrence)
				i++
			}
		}

		allOccurrences.sort((a, b) => {
			if (a.start < b.start) return -1
			if (a.start > b.start) return 1
			return 0
		})

		return allOccurrences
	}

	/**
	 * Adds an event to the timetable and commits the change
	 * @param event the event to add
	 */
	async addEvent(event: TimetableEvent) {
		console.log("adding event!", event)
		const existingEvent = this.getEvent(event.id)
		if (existingEvent) {
			console.log("existing", existingEvent)
			this.events.splice(
				this.events.findIndex((e) => e.id === event.id),
				1,
				event
			)
		} else {
			console.log("pushing", event)
			this.events.push(event)
		}
		await this.push()
	}

	/**
	 * Deletes an event from the timetable and commits the change
	 * @param event
	 */
	async deleteEvent(event: TimetableEvent) {
		this.events.splice(this.events.findIndex((e) => e.id === event.id))
		await this.push()
	}

	// #endregion Interface
	// #region Import / Export flow

	/**
	 * Pulls and imports the timetable data from the server
	 * @param push If true, send the device timetable update with the internal data
	 */
	async refresh() {
		const rawData: TimetableRawData = await this.pull()
		this.events = []
		this.importData(rawData)
	}

	/** Requests from the server the user's timetable */
	private async pull() {
		return (await this.device.platoCall("timetable_get_frontend", [])) as TimetableRawData
	}

	/** Sends an update to the device with the timetable data within this instance */
	async push() {
		const timetableJson = this.exportData()

		await this.device.platoCall("timetable_set", [timetableJson])
		await this.refresh()
	}

	/** Imports the raw data pulled from this.pull, creating event instances to populate this.events */
	private importData(data: TimetableRawData) {
		if (!data) return

		this.lastUpdated = new Date(data.metadata.last_modified)
		this.version = data.version

		for (const eventData of data.events) {
			let event: TimetableEvent
			try {
				if (eventData.repeat) {
					event = new TimetableEventRepeat(eventData, this.device) as TimetableEvent
				} else {
					event = new TimetableEventSingle(eventData, this.device) as TimetableEvent
				}
				this.events.push(event)
			} catch (e) {
				console.error("Caught broken event", eventData, e)
			}
		}

		this.triggerListeners()
	}

	/** Exports the data and events from this module to create a json block which can be sent to the server to update the timetable. */
	private exportData() {
		// filter out old play now events
		const events = this.events.filter((e) => {
			if (e.name !== TEMPORARY_PLAY_NOW_EVENT_NAME) return true
			// remove the event if it is a play now event and it is already over

			const end = DateTime.fromISO(e.getTimingValues().end)
			const now = DateTime.now()

			// if now is before the end of the event - return false and keep the event
			return (
				now >
				end.minus({
					minutes: 30,
				})
			)
		})

		const json = {
			metadata: {
				last_modified: DateTime.local().toUTC().toISO(),
			},
			events: events.map((event) => event.toJson()),
			version: this.version,
			precache_projects: [],
		}

		return json
	}

	// #endregion
}
