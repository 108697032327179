import { Device } from "./Device"
import { FirmwareProductByID, StatusColors } from "./consts"
import { Version } from "../../modules/Version"
import type { FirmwarePreferences, BaseEidosType, BaseDeviceRawData, DeviceStatusAppearance } from "."
import { LuxedoRPC } from "luxedo-rpc"
import { ThirdPartyProjectorManager } from "../../data/SupportedProjectors"
import { DataHandlerDevice } from "../../datahandlers/DataHandlerDevice"

// #region 		================================= 	TYPES 	=================================

export type StatusRPiDevice = "OFF" | "NEW" | "UPDATING" | "IDLE" | "PROJECT" | "PROJ_TT" | "PNC" | "LOST" | "DORMANT"
export type ProjectorPowerState = "ON" | "OFF" | "POWERING_ON" | "POWERING_OFF" | "UNDEFINED"

export interface LuxcastFWPreferences extends FirmwarePreferences {
	show_wifi_info?: boolean
	audio_device?: "HDMI" | "HEADPHONES"
	projector_keep_alive_duration?: number

	// Removed with firmware version 3.1.8
	// power_control_type?: "IR" | "RS232"
	// projector_manufacturer?: "OPTOMA" | "EPSON"
}

export interface EidosFirmware extends BaseEidosType {
	bluetooth?: {
		paired_entries: Array<number>
		scanned_entries: Array<number>
	}
	cache?: {
		cached_project_ids: Array<number>
		free_space_bytes: number
	}
	config?: {
		fw_config: LuxcastFWPreferences
	}
	display_config?: Array<number>
	display_mode?: string
	playback_type: "EMPTY" | "SCENE" | string
	message_queue?: Array<string>
	pnc?: {
		capture_id: number | "auto_cal"
		imgs_taken: number
		img_total: number
	}
	proj_id?: number
	proj_play_starttime?: number
	status: StatusRPiDevice
	projector_power?: {
		state: ProjectorPowerState // undefined is highly unlikely, so just treat it as OFF
		controller: "OptomaRS232Controller" | "EpsonRS232Controller" | "InfraredController"
	}
}

export interface DeviceRPiRawData extends BaseDeviceRawData {
	status: StatusRPiDevice
	type_id: "dev_luxedo" | "dev_luxcast"
	product_id: number
	proj_w?: number
	proj_h?: number
	version_crnt: string
	version_tgt?: string
	version_avail: string
	password?: string
	third_party_proj?: string
	eidos: EidosFirmware
	preferences: LuxcastFWPreferences
}

export interface DeviceRPi extends Device<DeviceRPiRawData> {
	_eidos: EidosFirmware
	_status: StatusRPiDevice
	typeId: "dev_luxedo" | "dev_luxcast"
	isResolutionChanging?: boolean
	firmwareVersion: string
	thirdPartyProjector?: string
	orientation?: boolean
	password?: string
}

// #endregion ================================= 	TYPES 	=================================

export class DeviceRPi extends Device<DeviceRPiRawData> {
	// #region 		=================================  Initialization 	=================================

	declare orientation?: boolean
	declare availableUpdate?: string
	protected versionTarget?: string

	protected lastStatusWasOnline: boolean

	constructor(data: DeviceRPiRawData) {
		super(data)
		this._rawData = data
	}

	// #endregion =================================  Initialization 	=================================
	// #region 		=================================  Import / Export  =================================

	/**
	 * Imports the resolution depending on the device's product type.
	 */
	protected importResolution(data: DeviceRPiRawData) {
		if (data.type_id === "dev_luxedo") {
			this.resX = FirmwareProductByID[data.product_id].proj_res[0]!
			this.resY = FirmwareProductByID[data.product_id].proj_res[1]!
		} else {
			this.resX = data.proj_w!
			this.resY = data.proj_h!
		}
	}

	/**
	 * Imports the device data, populating this device instance
	 * @param data The device data
	 */
	protected importData(data: DeviceRPiRawData): void {
		super.importData(data)

		this.password = data.password
		this.orientation = !!parseInt(data.orientation ?? "0")
		this.firmwareVersion = data.version_crnt
		this.thirdPartyProjector = data.third_party_proj ?? "_all_other_projectors"
		this.availableUpdate = data.version_avail
	}

	/**
	 * Converts this object to a JSON representation (used to push changes)
	 * @returns
	 */
	protected exportData(): Partial<DeviceRPiRawData> {
		return {
			name: this.name,
			ui_color: this._color,
			proj_h: this.resY,
			proj_w: this.resX,
		}
	}

	// #endregion =================================  Import / Export  =================================
	// #region 		=================================   Device Update   =================================

	isUpdateAvailable(): boolean {
		try {
			return Version.compare_strings(this.firmwareVersion, this.availableUpdate) < 0
		} catch (e) {
			console.warn("Error comparing device version to available versions.")
			return false
		}
	}

	async update(): Promise<void> {
		this.isUpdating = true
		this.lastStatusWasOnline = true
		await LuxedoRPC.api.deviceControl.device_update(this.id!)
	}

	// #endregion =================================   Device Update   =================================
	// #region 		=================================      Getters      =================================

	/**
	 * Gets the online status of the Luxedo device
	 */
	get isOnline() {
		const status = this._status
		return status == "IDLE" || status == "PROJ_TT" || status == "PROJECT" || status == "PNC"
	}

	/**
	 * Checks if the device is busy or available for action
	 */
	get isReady() {
		return this._status == "IDLE" || this._status == "PROJ_TT" || this._status == "PROJECT"
	}

	/**
	 * Checks the eidos to verify the status of the internal projector
	 */
	get isProjectorOn() {
		if (["ON", "POWERING_OFF"].includes(this._eidos.projector_power.state)) return true
		return false
	}

	/**
	 * Returns true if this device instance has an internal projector
	 */
	public get hasConnectedProjector() {
		return this instanceof DeviceRPi && this.typeId === "dev_luxcast"
	}

	/**
	 * Gets the lumen count of this device instance
	 * (NOT USED)
	 */
	get lumenCount() {
		if (!this.thirdPartyProjector || !ThirdPartyProjectorManager.projectors[this.thirdPartyProjector]) return null
		return ThirdPartyProjectorManager.projectors[this.thirdPartyProjector].lumens ?? 3500
	}

	/**
	 * Gets the user facing status text of this device
	 */
	get status() {
		return this.getStatus().text
	}

	/**
	 * Gets the color of the user facing status text
	 */
	get statusColor() {
		return this.getStatus().color
	}

	// #endregion =================================      Getters      =================================
	// #region 		=================================  Status Updates   =================================

	/**
	 * Called when the device's eidos is updated
	 * @param eidos
	 */
	onEidosUpdate(eidos: EidosFirmware): void
	onEidosUpdate(eidos: any): void
	onEidosUpdate(eidos: unknown): void {
		super.onEidosUpdate(eidos)

		console.warn({
			this: this,
			updateAvail: this.isUpdateAvailable(),
			firmware: this.firmwareVersion,
			avail: this.availableUpdate,
			eidos,
		})

		if (this.isUpdating && !this.isOnline && this.lastStatusWasOnline) {
			this.lastStatusWasOnline = false
		} else if (this.isUpdating && this.isOnline && !this.lastStatusWasOnline) {
			if (!this.isUpdateAvailable()) {
				this.isUpdating = false
				DataHandlerDevice.pull([this.id!])
			} else DataHandlerDevice.pull([this.id!])
		}
	}

	/**
	 * A mapping of each eidos status w/ user facing text and a color
	 */
	protected statusAppearanceMap: Record<StatusRPiDevice, DeviceStatusAppearance> = {
		OFF: { text: "Offline", color: StatusColors.off },
		LOST: { text: "Offline", color: StatusColors.off },
		NEW: { text: "Offline", color: StatusColors.off },
		UPDATING: { text: "Updating...", color: StatusColors.updating },
		IDLE: { text: "Idle", color: StatusColors.idle },
		PROJECT: { text: "Playing", color: StatusColors.playing },
		PROJ_TT: { text: "Playing", color: StatusColors.playing },
		PNC: { text: "Capturing...", color: StatusColors.projectAndCapture },
		DORMANT: { text: "Searching...", color: StatusColors.initializing },
	}

	// #endregion =================================  Status Updates   =================================
	// #region    ================================= Device Operations =================================

	// loadingPowerControl: Promise<void> | undefined = undefined

	/**
	 * Sends a projector power signal to the backend, resolves when the new projector state reflects the provided status.
	 * @param newPower The intended power signal of the internal projector
	 */
	async setProjectorPower(newPower: "ON" | "OFF"): Promise<void> {
		// if (this.loadingPowerControl) return this.loadingPowerControl
		return new Promise(async (res, rej) => {
			const resolve = (fail?: boolean) => {
				if (fail) rej()
				else res()
				// this.loadingPowerControl = undefined
			}
			if (newPower === this.eidos.projector_power?.state) return resolve()

			try {
				await LuxedoRPC.api.deviceControl.devops_set_projector_power(this.id!, newPower)
				await this.listenEidosCondition((eidos) => eidos.projector_power?.state == newPower, 20)
				resolve()
			} catch (e) {
				console.error("An error occurred while trying to set projector power.", e)
				resolve(true)
			}
		})
	}

	// #endregion ================================= Device Operations =================================
}
