import { EditorAsset } from "../asset"
import {
	convertInstanceToObject,
	getSerializedConstructor,
	registerSerializableConstructor,
} from "../modules/serialize"
import { Mask } from "../asset"
import { EditorClass, getEditor } from ".."
import { TimeSpan, Track, TrackBaseOptions, TrackLike, TrackOptions } from "."
import { Group } from "fabric/fabric-impl"

export interface NewTrackOptions extends TrackOptions {
	index?: number
}

export class TrackGroup<T extends EditorAsset = any> extends TrackLike<T> {
	/** An array of tracks/groups, in their layer order (bottom to top) */
	children: Array<Track<T> | TrackGroup<T>> = []
	parentId?: string

	//#region    ===========================				 Construction  				==============================

	constructor(editor: EditorClass, options: TrackBaseOptions) {
		super(editor, options)
		this.contentType = "GROUP"
	}

	/**
	 * Creates a new track with a passed asset and adds it to this group
	 * This or createAsset is the preferred way of adding assets to groups
	 * @param asset an asset instance to insert
	 * @param options configurations for the new track
	 * @param options.index the position in the tracklist to put the new track at
	 * @returns the inserted track
	 */
	addAsset<C extends T>(asset: C, trackOptions: NewTrackOptions): Track<C> {
		console.warn("Adding asset", asset)
		const track = Track.FromAsset(this.editor, asset, trackOptions)

		this.attach(track, trackOptions.index)
		this.emitEvent("tracklist:add", {
			track: track,
		})

		getEditor().tracks.emitEvent("tracklist:add", { track: track })

		return track
	}

	/**
	 * Create a new child group
	 * This is the preferred way of generating groups. You shouldn't call new TrackGroup() pretty much ever.
	 *
	 * @returns the created group
	 */
	createSubgroup(options: TrackBaseOptions, index?: number): TrackGroup<T> {
		const group = new TrackGroup<T>(this.editor, options)

		this.attach(group, index)

		this.emitEvent("tracklist:add", {
			track: group,
		})
		return group
	}

	//#endregion =====================================================================================================

	//#region    ===========================				 Properties  				==============================

	/** Gets the number of children - May be 0 */
	get size(): number {
		return this.children.length
	}

	isEmpty(): boolean {
		return this.size == 0
	}

	isRoot(): boolean {
		return false
	}

	//#endregion =====================================================================================================

	//#region    ===========================				Contents API				==============================

	/** checks if a child can be added to a group, based on contentType and infinite loops */
	canContain(trackOrGroup: Track<any> | TrackGroup<any>): boolean {
		return this.getRoot().canContain(trackOrGroup)
	}

	/**
	 * Add a track/group to contents
	 * @param child Another track/group
	 * @param index insertion index. 0 = bottom, -1 = top ; default = -1
	 * @returns the inserted instance
	 */
	attach(child: Track<T> | TrackGroup<T>, index?: number): Track<T> | TrackGroup<T> {
		if (!child.initialized) {
			child.initialize(this.getRoot())
		}

		if (child.parent) {
			child.parent.detach(child)
		}

		index = index ?? -1

		if (index == -1 || index >= this.children.length) this.children.push(child)
		else this.children.splice(index, 0, child)

		child.cachedParent = this

		this.emitEvent("tracklist:add", {
			track: child,
		})

		child.emitEvent("tracklist:move", {
			track: this,
		})

		return child
	}

	get duration() {
		return this.end - this.start
	}

	get start() {
		if (!this.children.length) return 0

		let start = this.children[0].start
		for (let i = 1; i < this.children.length; i++) {
			const child: Track | TrackGroup = this.children[i]
			if (child.start < start) start = child.start
		}
		return start
	}

	get end() {
		if (!this.children.length) return getEditor().timeline.duration

		let end = this.children[0].end
		for (let i = 1; i < this.children.length; i++) {
			const child: Track | TrackGroup = this.children[i]
			if (child.end > end) end = child.end
		}
		return end
	}

	/**
	 * Only adjust the start (by adjusting the position of ALL children).
	 * This should only be called when moving a track
	 */
	setTimespan(timespan: Partial<TimeSpan>) {
		timespan.start = timespan.start ?? this.start
		timespan.end = timespan.end ?? this.end

		if (timespan.start >= timespan.end) throw RangeError("start cannot be after end")

		const startOffset = timespan.start - this.start

		console.log(startOffset)

		for (const child of this.children) {
			child.setTimespan({ start: child.start + startOffset, end: child.end + startOffset })
		}

		this.emitEvent("track:edittimespan", {
			track: this,
		})
	}

	/**
	 * Delete a child entirely (use .detach() to prepare to move a child to another track instead)
	 * @param child The group / track to delete
	 */
	delete(child: Track<T> | TrackGroup<T>): void {
		const childIndex = this.children.indexOf(child)

		if (childIndex == -1) throw ReferenceError(`This group does not contain the child ${child.name}`)

		if (child instanceof TrackGroup) {
			child.deleteAll()
		}

		this.detach(child)
		this.getRoot().deregister(child)

		if (child.hasOwnMask()) {
			child.clearMask()
		}

		if (child instanceof Track) {
			child.onDelete()
		}

		child.emitEvent("tracklist:delete", {
			track: child,
		})
	}

	/** Delete all contents. Automatically called when deleted by a parent. */
	deleteAll(): void {
		for (const child of this.children.toReversed()) {
			this.delete(child)
		}
	}

	//#endregion =====================================================================================================

	//#region    ===========================			Traversal/Utility				==============================

	getAssets() {
		return this.children.flatMap((track) => track.getAssets())
	}

	/**
	 * returns true if the specified track/group is within this group
	 * This check IS RECURSIVE!
	 */
	contains(child: Track<any> | TrackGroup<any>): boolean {
		return child.getLineage().includes(this)
	}

	/**
	 * Check if the passed tracklike is a child of this group
	 * @param child
	 * @returns
	 */
	parentOf(child: Track<any> | TrackGroup<any>): boolean {
		return this.children.includes(child)
	}

	/**
	 * Find the index of the child if it exists in the top level of contents
	 * @returns index of child, or -1 if it isn't in this group
	 **/
	indexOf(child: Track<T> | TrackGroup<T>): number {
		return this.children.indexOf(child)
	}

	/**
	 * Applies the specified function to every child in order, depth-first
	 * @returns every child that was visited, in the order it was visited
	 **/
	traverse(fn?: (target: Track<T> | TrackGroup<T>) => void): Array<Track<T> | TrackGroup<T>> {
		let visited = []

		for (const child of this.children) {
			visited.push(child)

			if (fn) fn(child)

			if (child instanceof TrackGroup) {
				visited = visited.concat(child.traverse(fn))
			}
		}

		return visited
	}

	/**
	 * Applies the specified function to every DIRECT child in order
	 **/
	forEach(fn?: (target: Track<T> | TrackGroup<T>) => void): Array<Track<T> | TrackGroup<T>> {
		let visited = []

		for (const child of this.children) {
			visited.push(child)

			if (fn) fn(child)
		}

		return visited
	}

	/** Iterate through and recursively update the parent cache for this group and any groups contained */
	refreshCache() {
		for (const group of this.traverse().filter((tracklike) => tracklike instanceof TrackGroup)) {
			if (!(group instanceof TrackGroup)) continue

			group.children.forEach((child) => {
				child.cachedParent = group
			})
		}
	}

	/**
	 * Recursively find specified nodes in the contents (depth first)
	 * @param filterFunction function used for matching properties of each item with the desired
	 * @returns array of matched children (with correct parent caches)
	 * @returns null if not found
	 */
	search(matchFunction: (searchTarget: Track<T> | TrackGroup<T>) => boolean): Array<Track<T> | TrackGroup<T>> {
		return this.traverse().filter(matchFunction)
	}

	//#endregion =====================================================================================================

	//#region    ===========================			   Track order API				==============================

	/**
	 * Set child position within content list
	 * @param child The child to move
	 * @param index the new position. 0 = bottom, n = nth from bottom, -1 = top.
	 * @throws {TypeError} - if called on a track
	 */
	moveChild(child: Track<T> | TrackGroup<T>, index: number): void {
		this.attach(child, index)
		this.emitEvent("tracklist:move", {
			track: child,
		})
	}

	/**
	 * Move a child up or down in the order
	 * @param child The child to move
	 * @param delta the amount to move by
	 * @throws {TypeError} - if called on a track
	 */
	moveChildRelative(child: Track<T> | TrackGroup<T>, delta: number): void {
		const newPosition = Math.max(0, this.children.indexOf(child) + delta)
		this.moveChild(child, newPosition)
	}

	/**
	 * Detach a child so it can be added to a different group
	 * @param child The group / track to delete
	 */
	detach(child: Track<T> | TrackGroup<T>): void {
		if (!this.parentOf(child)) throw ReferenceError(`This group does not contain the child ${child.name}`)

		const index = this.children.indexOf(child)
		this.children.splice(index, 1)

		child.cachedParent = undefined

		this.emitEvent("tracklist:beforemove", {
			track: child,
		})
	}

	//#endregion =====================================================================================================

	maskUpdated(evented: boolean = true): void {
		super.maskUpdated(evented)

		if (this instanceof TrackGroup) {
			this.children.forEach((child) => {
				child.maskUpdated()
			})
		}
	}

	generateMask(): void {
		super.generateMask()

		this.children.forEach((child) => child.generateMask())
	}

	// #region ===========================  			 Serialization 				==============================

	// getDefaultPropertyExports(): { include: any[]; deepCopy: any[]; exclude: string[] } {
	// 	const props = super.getDefaultPropertyExports()

	// 	return props
	// }

	static async loadJSON(editor: EditorClass, data: Partial<TrackGroup>, id?: string): Promise<TrackGroup> {
		const instance = new this(editor, {
			name: data.name,
			id,
		})

		instance.tags = data.tags
		instance.hidden = data.hidden ?? false
		instance.locked = data.locked ?? false
		instance.muted = data.muted ?? false

		// Mask importing handled by `importer`

		// if (data.clippingMask) {
		// 	data.clippingMask.bindTrack(instance)

		// 	const constructor = getSerializedConstructor(data.clippingMask) // Cannot reference the ClippingMask class directly due to odd load order issues
		// 	const mask = await constructor.loadJSON(editor, data.clippingMask)

		// 	instance.setMask(mask as Mask)
		// }

		return instance
	}

	serialize(forExport?: boolean): Partial<this> {
		let json
		if (forExport) {
			json = convertInstanceToObject(this, {
				propertiesToExclude: ["children", "trackMask"],
				forExport,
			})
			json["children"] = this.children.map((track) => track.id)
		} else {
			json = convertInstanceToObject(this, {
				propertiesToDeepCopy: ["children", "trackMask"],
				forExport,
			})
		}

		if (this.maskData) {
			json["maskData"] = this.maskData
			if (!("inverted" in json["maskData"])) json["maskData"]["inverted"] = false
		}

		json["parentId"] = this.parent.id

		return json
	}

	// #region =======================================================================================
}

registerSerializableConstructor(TrackGroup)
