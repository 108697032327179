<script lang="ts">
	import type Dropzone from "dropzone"

	import { DataHandlerMedia, Media } from "luxedo-data"
	import type { Folder } from "luxedo-data/src/entries/Folder"

	import { Toast } from "../../toaster"
	import { LuxedoRPC } from "luxedo-rpc"
	import { DropzoneInput } from "../../inputs"
	import { UploadProcessManager } from "../../stores/UploadProcessManager"

	interface UploadFile extends Dropzone.DropzoneFile {
		hash: string
		name: string
		type: string
	}

	export let activeFolder: Folder
	export let postSubmit: () => void

	let dropzone: Dropzone
	let isUploading = false

	let processingIndex: number = 0
	let pendingFiles: Array<UploadFile> = []
	let uploadedFiles: Array<UploadFile> = []
	let completedFiles: Array<Media> = []

	let fileIdHashMap: {
		[index: string]: number
	} = {}

	/**
	 * Resets state to avoid odd behaviors
	 */
	function resetState() {
		fileIdHashMap = {}
		pendingFiles = []
		uploadedFiles = []
		completedFiles = []
		processingIndex = 0
		isUploading = false
		dropzone && dropzone.removeAllFiles()
	}

	/**
	 * Called when dropzone form is submitted
	 */
	async function onUpload(files: Array<UploadFile>, name: string, dz: Dropzone) {
		resetState()
		isUploading = true
		for (const file of files) {
			await createFileRecord(file)
			pendingFiles.push(file)
		}
		dz.processQueue()
	}

	/**
	 * Called by Dropzone when the file data is being sent to the server. Called AFTER createFileRecord
	 */
	async function onDropzoneSend(file: UploadFile, xhr: XMLHttpRequest, formData: FormData) {
		formData.append("file_id", String(fileIdHashMap[file.hash]))
	}

	/**
	 * Creates a DB record of the new media file before submitting the actual file
	 * @param uploadFile
	 */
	async function createFileRecord(uploadFile: UploadFile) {
		let [name] = splitFileName(uploadFile.name)
		name = name.substring(0, 64)

		const entryId = await DataHandlerMedia.createEntry({
			name,
			parent_id: activeFolder.id,
			storage_mimetype: uploadFile.type,
		})
		const fileHash = DataHandlerMedia.get(entryId).hash()

		uploadFile.hash = fileHash
		fileIdHashMap[fileHash] = entryId
	}

	/**
	 * Delete all DB entries that have been uploaded during this session. (Used in case of upload/processing failure)
	 */
	async function deleteAttemptedUploads() {
		for (const file of uploadedFiles) {
			const fileId = fileIdHashMap[file.hash]
			const entry = DataHandlerMedia.get(fileId)
			await DataHandlerMedia.deleteEntry(entry)
		}
	}

	/**
	 * Called by Drozone in case the upload fails
	 */
	function onFail(file: UploadFile, message: string | Error, xhr: XMLHttpRequest) {
		Toast.error(`Error uploading files. ${message}`)
		deleteAttemptedUploads()
		resetState()
	}

	/**
	 * Called by Dropzone when the upload is succesful
	 */
	async function onSuccess(file: UploadFile) {
		uploadedFiles.push(file)
		if (uploadedFiles.length === pendingFiles.length) {
			beginProcessingFiles()
		}
	}

	let isProcessing = false
	async function beginProcessingFiles() {
		if (isProcessing) return
		isProcessing = true

		Toast.success("File(s) uploaded! Processing now...")

		await processNextFile()
	}

	/**
	 * After db entries are created and the file itself has been uploaded to the server, process each file to generate thumbnails, ect.
	 */
	async function processNextFile() {
		if (processingIndex === uploadedFiles.length) {
			await DataHandlerMedia.pull(completedFiles.map((media) => media.id))
			Toast.success("All files successfully processed!")
			postSubmit()
			isUploading = false
			return
		}

		const fileId = fileIdHashMap[uploadedFiles[processingIndex].hash]
		const fileEntry = DataHandlerMedia.get(fileId)
		const mimetype = fileEntry.mimetype

		try {
			await LuxedoRPC.api.media.media_process(fileId, mimetype)
			await UploadProcessManager.awaitMediaProcessing(fileId)
			processingIndex++
			completedFiles.push(fileEntry)
			await processNextFile()
		} catch (error) {
			deleteAttemptedUploads()
			Toast.error("Unable to process file.")
			console.error("Unable to process media file.", error)
		}
	}

	/**
	 * Splits a filename into the file name and file extension
	 * @param filename
	 * @returns [file name, file extension]
	 */
	function splitFileName(filename: string): [string, string] {
		const index = filename.lastIndexOf(".")
		if (index == -1) return [filename, ""]
		return [filename.substring(0, index), filename.substring(index + 1).toLowerCase()]
	}
</script>

<div class="dropzone-wrapper">
	<!-- uploadUrl={`${import.meta.env.VITE_API_URL}/workspace/media/splitupload`} -->
	<DropzoneInput
		isLoading={isUploading}
		uploadButtonId="media-upload-button"
		uploadMultiple
		uploadUrl={`/workspace/media/splitupload`}
		{onUpload}
		{onDropzoneSend}
		{onFail}
		{onSuccess}
	/>
</div>

<style>
	:global(.file-uploader) {
		width: 100%;
		height: 8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		border-radius: var(--br);
		background-color: var(--color-bg);
		color: var(--color-text);
		padding: 1rem;
	}

	.dropzone-wrapper {
		height: 100%;
	}

	.dropzone-wrapper :global(.button-container) {
		position: absolute;
		bottom: 0;
		right: 1rem;
	}
</style>
