import { DataHandlerDevice, Device, DeviceGroup, DeviceRPi, type EidosTypeOf } from "../../index"
import { writable, get as storeGet } from "svelte/store"

export namespace ProjectorPowerManager {
	type PowerManagerContext = {
		[index: number]: {
			state: "ON" | "OFF" | "POWERING_ON" | "POWERING_OFF" | "UNDEFINED"
			isChanging: boolean
		}
	}

	// #region		==========================  STORE INTERFACE  ==========================
	const store = writable<PowerManagerContext>()

	export function subscribe(cb: (ctx: PowerManagerContext) => void) {
		return store.subscribe(cb)
	}

	export function get(deviceId: number) {
		return storeGet(store)[deviceId] ?? { state: "UNDEFINED", isChanging: false }
	}

	// #endregion	==========================  STORE INTERFACE  ==========================
	// #region		==========================   DEVICE STATE    ==========================

	/**
	 * Called when a device receives an eidos update. Updates the state, but not the `isChanging` property.
	 * @param device the updated device
	 */
	function onDeviceUpdate(device: DeviceRPi) {
		store.update((ctx) => {
			const existing = ctx[device.id]
			return {
				...ctx,
				[device.id]: {
					...existing,
					state: device.getEidos()?.projector_power?.state ?? "UNDEFINED",
				},
			}
		})
	}

	// #endregion	==========================   DEVICE STATE    ==========================
	// #region		========================== MANAGER INTERFACE ==========================

	function initializeDevice(device: Device) {
		if (!device) return
		const data = storeGet(store)
		if (!(device.id in data))
			store.update((ctx) => ({
				...ctx,
				[device.id]: {
					state: device.getEidos()?.projector_power?.state ?? "UNDEFINED",
					isChanging: false,
				},
			}))
	}

	/**
	 * Loops through user owned devices and creates a store record for each with projector power capabilities
	 */
	export function initialize() {
		const devices = DataHandlerDevice.getMany()
		const init: PowerManagerContext = {}
		for (const device of devices) {
			if (device instanceof DeviceRPi) {
				init[device.id] = {
					state: device.getEidos()?.projector_power?.state,
					isChanging: false,
				}
				setTimeout(() => {
					device.addUpdateListener(onDeviceUpdate)
				})
			} else {
				init[device.id] = {
					state: "UNDEFINED",
					isChanging: false,
				}
			}
		}
		store.set(init)

		DataHandlerDevice.addListener((ids) => {
			for (const id of ids) {
				initializeDevice(DataHandlerDevice.get(id))
			}
		})
	}

	/**
	 * Sets the projector power for the specified device
	 * @param device the device to update
	 * @param newPower the new power state
	 */
	export async function setPower(device: DeviceRPi | DeviceGroup, newPower: "ON" | "OFF") {
		store.update((ctx) => ({
			...ctx,
			[device.id]: {
				state: ctx[device.id!].state,
				isChanging: true,
			},
		}))

		await device.setProjectorPower(newPower)

		store.update((ctx) => ({
			...ctx,
			[device.id]: {
				state: ctx[device.id!].state,
				isChanging: false,
			},
		}))
	}

	// #endregion	========================== MANAGER INTERFACE ==========================
	// #region 		========================== 		UTILITIES			 ==========================

	export function convertToReadableState(state: "ON" | "OFF" | "POWERING_ON" | "POWERING_OFF" | "UNDEFINED") {
		switch (state) {
			case "OFF":
				return "Powered off"
			case "ON":
				return "Powered on"
			case "POWERING_ON":
				return "Powering on"
			case "POWERING_OFF":
				return "Powering off"
			case "UNDEFINED":
				return "Unknown power state"
			default:
				return "Loading..."
		}
	}

	// #endregion ========================== 		UTILITIES			 ==========================
}
