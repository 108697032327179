<script lang="ts">
	import ProjectorIcon from "../../../reusable/icons/ProjectorIcon.svelte"
	import ProjectorInfoAbout from "./about/ProjectorInfo_About.svelte"
	import ProjectorRegistration from "./registration/ProjectorRegistration.svelte"
	import DeviceColorPicker from "./device-color-picker/DeviceColorPicker.svelte"
	import { TextRowSelector } from "svelte-comps/inputs"
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
	import { openDeviceColorPicker } from "./device-color-picker/DeviceColorPickerContext"
	import { ProjectorRegistrationContext } from "../ProjectorRegistrationContext"
	import {
		DataHandlerDevice,
		type Device,
		DeviceGroup,
		DeviceBrowser,
		DeviceRPi,
	} from "luxedo-data"
	import ProjectorInfoSync from "./ProjectorInfo_Sync.svelte"
	import ProjectorInfoAdvanced from "./ProjectorInfo_Advanced.svelte"
	import { launchLuxLink } from "./LuxLinkLauncher"
	import ProjectorInfoCal from "./calibration/ProjectorInfo_Cal.svelte"
	import { tooltip } from "svelte-comps/tooltip"
	import { openUpdateInformerOverlay } from "../../../reusable/overlays/device-update"

	const MENU_MAP = {
		Calibration: ProjectorInfoCal,
		Info: ProjectorInfoAbout,
		Advanced: ProjectorInfoAdvanced,
	}

	let device: Device
	let listenerId: string
	let childDevices: {
		[index: number]: Device
	} = {}

	let activeMenu: keyof typeof MENU_MAP = Object.keys(MENU_MAP)[0] as keyof typeof MENU_MAP
	let deviceIconElem: SVGElement
	let triggerSave: () => Promise<void>

	let isPendingUpdate: boolean
	let isUpdating: boolean

	function openColorInput() {
		openDeviceColorPicker(onColorChange, deviceIconElem)
	}

	async function onColorChange(colorIndex: number) {
		device._color = colorIndex
		await DataHandlerDevice.push([device])
		SelectedDeviceStore.set(device)
	}

	async function triggerUpdate() {
		openUpdateInformerOverlay(device, (device as DeviceRPi).availableUpdate)
	}

	SelectedDeviceStore.subscribe((dev) => {
		if (listenerId && device) device.removeUpdateListener(listenerId)

		device = dev
		isUpdating = false
		isPendingUpdate = false

		if (device) {
			listenerId = device.addUpdateListener((dev) => {
				device = dev
				isUpdating = device.isUpdating

				setTimeout(() => {
					if (device.isUpdateAvailable()) {
						isPendingUpdate = true
					} else isPendingUpdate = false
				})
			})
			ProjectorRegistrationContext.set({
				isRegistering: false,
			})

			if (device instanceof DeviceGroup) {
				MENU_MAP["Calibration"] = ProjectorInfoSync
				for (const child of device.children) {
					childDevices[child.id] = DataHandlerDevice.get(child.device_id)
				}
			} else {
				MENU_MAP["Calibration"] = ProjectorInfoCal
			}

			if (device.isUpdateAvailable()) {
				isPendingUpdate = true
			} else isPendingUpdate = false
		}
	})
</script>

<div id="projector-info">
	{#if !device}
		<ProjectorRegistration />
	{:else}
		<div class="flex-column">
			<div class="flex-row">
				<DeviceColorPicker />
				<div class="icon-container">
					<ProjectorIcon {device} onClick={openColorInput} bind:deviceIconElem />
					<div class="icon-bg" />

					{#if device instanceof DeviceGroup && Object.values(childDevices).length === device.children.length}
						{#each Object.values(childDevices) as device}
							<div
								style="background-color: {device && 'color' in device ? device.color : ''}"
								class="icon-bg-color"
							/>
						{/each}
					{:else}
						<div style="background-color: {device.color}" class="icon-bg-color" />
					{/if}
				</div>
				<div class="flex-column device-title-row">
					<h1 id="device-name">{device.name}</h1>
					<span class="projector-status">
						<div
							class="projector-status-indicator"
							style="background-color: {device.statusColor};"
						/>
						{device.status}
					</span>
				</div>
			</div>
			{#if isPendingUpdate && !isUpdating && device.isOnline}
				<button
					id="update-device-button"
					class="outline-button"
					on:click={triggerUpdate}
					use:tooltip={{
						content: "An new device version is available. Click now to update your device.",
						pointing: "top",
					}}>Update Now</button
				>
			{/if}
			{#if device instanceof DeviceBrowser && !DeviceBrowser.ONLINE_STATUSES.includes(device._status)}
				<button
					id="LuxLink-launcher"
					class="outline-button"
					on:click={() => launchLuxLink(device instanceof DeviceBrowser ? device : undefined)}
					>Start LuxLink</button
				>
			{/if}
		</div>
	{/if}
	<hr />
	<div class="menu-navigator">
		<TextRowSelector
			bind:selectedOption={activeMenu}
			options={Object.keys(MENU_MAP)}
			optionInfo={{
				Calibration: `Calibration is the process of shining and capturing patterns from your Luxedo in order to accurately map your projection space.`,
				Advanced: `These settings are intended for advanced users or specific troubleshooting scenarios. Most users won't need to tinker with them.`,
			}}
			fontSize="var(--h1)"
		/>

		{#if triggerSave && device}
			<button on:click={triggerSave}>Save</button>
		{/if}
	</div>
	<div class="info-container">
		{#if device}
			<svelte:component this={MENU_MAP[activeMenu]} bind:triggerSave />
		{:else}
			<p id="no-selected-device">
				Complete device registration to adjust settings or view device information.
			</p>
		{/if}
	</div>
</div>

<style>
	#projector-info {
		padding: 2rem 2rem 0 2rem;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	#projector-info > .flex-column > .flex-row > .flex-column {
		overflow: hidden;
	}

	#LuxLink-launcher {
		margin-top: 1rem;
		width: 100%;
	}

	.projector-status {
		line-height: 1rem;
		color: var(--color-text);
		display: flex;
		align-items: center;
	}

	.device-title-row {
		overflow: hidden;
	}

	.projector-status-indicator {
		margin-right: 0.5rem;
		width: 0.5rem;
		height: 0.5rem;
		border-radius: 100%;
	}

	h1#device-name {
		font-size: 3rem;
		color: var(--color-text-light);
		margin: 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.info-container {
		flex-grow: 1;
		position: relative;
		padding: 1rem 0 0 0;
		/* padding: 1rem 2rem 0 2rem; */
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		overflow-y: hidden;
	}

	.menu-navigator {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	#projector-info :global(.text-row-selector button.link-button) {
		border: none;
	}

	.info-container:has(#no-selected-device) {
		justify-content: center;
	}

	#no-selected-device {
		text-align: center;
		font-size: var(--h1);
		color: var(--color-text);
	}

	#update-device-button {
		width: 100%;
		margin-top: 1rem;
	}

	/* #projector-info :global(.text-row-selector button.link-button) {
		color: var(--color-text-light);
	}

	#projector-info :global(.text-row-selector button.link-button.selected) {
		color: var(--color-main);
	} */
</style>
